<template>
  <div
    class="mmm-container"
    style="background: #fff; height: auto; min-height: 100%;"
  >
    <div v-if="!showLogistics" class="wx_loading2">
      <i class="wx_loading_icon"></i>
    </div>
    <!-- <div style="text-align: center" v-if="!showLogistics">
           物流信息查询中...
       </div>-->

    <div v-if="showLogistics">
      <div v-for="(item, index) in showLogistics" :key="index">
        <li
          class="in-list"
          style="background: #25ae5f;border-bottom: 1px solid #fff;"
          v-if="showLogistics.length > 1"
          @click="hide(index, item.mailNo)"
        >
          <div class="in-item">
            <div class="in-item-info">
              <div>
                <span class="myMM-Order" style="color: #fff"
                  >{{$t('包裹')}} {{ index + 1 }}
                  <a v-if="item.state && stateVal(item.state)" style="color: #f9f400">{{ $t(`${stateVal(item.state)}`) }}</a>
                </span>
              </div>
              <div class="view">
                <span
                  class="myMM-AllOrder"
                  >{{$t('查看')}}</span
                >
                <van-icon name="arrow-down" style="color: #fff;font-size: 12px;" />
              </div>
            </div>
          </div>
        </li>
        <div
          v-if="Index == index || (showLogistics.length == 1 && Index == -1)"
        >
          <div
            v-if="logisticsProduct && logisticsProduct.length > 0"
            style="display: flex; align-items: center;margin: 10px 0 10px 10px;"
          >
            <div style="display: flex; align-items: center;">
              <div
                v-for="(item, index) in logisticsProduct"
                :key="index"
                style="width: 60px; height: 60px; margin-right: 10px;    border: 1px solid #eee;box-sizing: border-box;"
              >
                <img
                  style="width:58px; height: 58px;"
                  :src="getProductImageUrl(item.picturePath,234)"
                />
              </div>
            </div>
            <div
              style="flex: 0 0 50px; text-align: center; width: 50px; font-size: 12px"
              @click="lookMore"
            >
            {{$t(`更多`)}}>
            </div>
          </div>
          <!-- <div v-if="item.routeMap"><div><iframe :src="item.routeMap" width="100%" height="400" frameborder="0"></iframe></div></div> -->
          <div v-if="item.routeMap">
            <div>
              <iframe
                :src="item.routeMap"
                width="100%"
                height="400"
                frameborder="0"
              ></iframe>
              <!-- 温馨提示 -->
            </div>
          </div>

          <div class="new-order-track" style="overflow: hidden;">
            <img
              :src="getProductImageUrl(item.expressIcon,234)"
              style="float: left;width: 56px;height: 56px;"
            />
            <div style="float: left; margin: 0 0  0 8px">
              <div>{{$t('运单编号')}}：{{ item.mailNo }}</div>
              <div>{{$t('物流公司')}}：{{ item.expressName }}</div>
              <div class="new-txt-rd2">{{$t('状态')}}：{{item.state && stateVal(item.state) ? $t(`${stateVal(item.state)}`):'' }}</div>
            </div>
          </div>
          <div style="margin-top: 20px;text-align: center" v-if="!item.message">
            {{$t('物流信息查询中')}}...
          </div>
          <div
            class="new-order-flow new-p-re"
            v-if="item.message"
            style="margin: 0 15px;"
          >
            <ul class="new-of-storey">
              <!--<div>-->
              <li
                :class="index === 0 ? 'on' : ''"
                v-for="(item1, index) in item.data"
                :key="index"
              >
                <span :class="index === 0 ? 'icon on' : 'icon'"></span>
                <span>{{ item1.context }}</span>
                <span>{{ item1.time }}</span>
              </li>
              <!--</div>-->
              <li class="on" v-if="item.message != 'ok'">
                <span class="icon on"></span>
                <span>{{ item.message }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <img
      v-if="showProducts"
      @click="hidePro"
      style="position: fixed; top: 70px;width: 30px; left: 50%;z-index: 111;margin-left: -15px;"
      src="@/assets/image/close.png"
    />
    <div
      v-if="showProducts"
      style="position: fixed; top: 110px;width: 80%;left: 10%;background: #fff;bottom: 70px;overflow: scroll;padding: 10px;z-index:111;box-sizing: border-box;"
    >
      <div style="text-align: center; line-height:30px"> {{$t('商品详情')}}</div>

      <div
        v-for="(item, index) in logisticsProduct"
        :key="index"
        style="display: flex; font-size: 14px"
      >
        <div>
          <img
            style="width:80px; height: 80px;"
            :src="getProductImageUrl(item.picturePath, 234)"
          />
        </div>
        <div
          style="display: flex; flex-direction: column; align-items: flex-start; justify-content: space-around; flex: 1"
        >
          <div>{{ item.productName }}</div>
          <div
            style="display: flex; align-items: center; justify-content: space-between; width: 100%"
          >
            <div style="color: red">￥{{ item.unitPrice }}</div>
            <div>X{{ item.amount }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showProducts" class="mask" @click="hidePro"></div>
  </div>
</template>
<script>
import Mixins from "@/utils/mixins";
import { getSearchExpressList2,getLogisticsProduct } from "@/api/logistics";
import i18n from '@/i18n'
export default {
  data: () => ({
    Index: -1,
    showProducts: false,
    showLogistics: [],
    logisticsProduct: [],
    langEnv:null
  }),
  mixins: [Mixins],
  created() {
   this.langEnv=this.$route.query.langEnv
    this.getList();
   if (this.showLogistics.length == 1) this.getLogisticsProductList(this.showLogistics[0].mailNo)
   console.log( this.langEnv);
  if( this.$route.query.langEnv==2){
    i18n.global.locale.value = 'en'
  }else if(this.$route.query.langEnv==3){
    i18n.global.locale.value = 'zh'
  }
  },

  methods: {
    async getLogisticsProductList(mailNo) {
    
   const res=  await  getLogisticsProduct({
      mailNo: mailNo,
      orderNo:this.$route.params.mailNo
     },this.langEnv)
     this.logisticsProduct=res.data
    },
  async getList() {
       const ids=[]
       const arr=this.$route.params.mailNo.split(',')
       arr.map(item=>{
        return ids.push(item)
       })
     const res= await getSearchExpressList2({
        ids
      },this.langEnv);
      this.showLogistics=res.data

    },
    lookMore() {
      this.showProducts = true;
    },
    hidePro() {
      this.showProducts = false;
    },
    hide(index, mailNo) {
      if (this.Index == index) {
        this.Index = -1;
        return;
      }
      this.Index = index;
     this.getLogisticsProductList(mailNo)
    },
    stateVal(type) {
      let state = "";
      switch (type) {
        case "-1":
          state = "無軌迹";
          break;
        case "0":
          state = "在途中";
          break;
        case "1":
          state = "已攬件";
          break;
        case "2":
          state = "疑難件";
          break;
        case "3":
          state = "已簽收";
          break;
        case "301":
          state = "正常簽收";
          break;
        case "302":
          state = "派件異常後最終簽收";
          break;
        case "304":
          state = "代收签收";
          break;
        case "311":
          state = "代收簽收";
          break;
        case "4":
          state = "快遞櫃或驿站簽收";
          break;
        case "5":
          state = "已退簽";
          break;
        case "6":
          state = "派件中";
          break;
        case "201":
          state = "退回中";
          break;
        case "202":
          state = "派件中";
          break;
        case "211":
          state = "已放入快遞櫃或驿站";
          break;
        case "401":
          state = "401-發貨無信息";
          break;
        case "402":
          state = "402-超時未簽收";
          break;
        case "403":
          state = "403-超時未更新";
          break;
        case "404":
          state = "拒收(退件)";
          break;
        case "405":
          state = "405-派件異常";
          break;
        case "406":
          state = "退貨簽收";
          break;
        case "407":
          state = "退貨未簽收";
          break;
        case "412":
          state = "快遞櫃或驿站超時未取";
          break;
      }
      return state;
    }
  }
};
</script>
<style lang="scss" scoped>
.mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wx_loading2 {
  text-align: center;
  padding: 5px;
  height: 32px;
  position: relative;
}

.wx_loading_icon {
  position: absolute;
  top: 15px;
  left: 50%;
  margin-left: -16px;
  width: 24px;
  height: 24px;
  border: 2px solid #fff;
  border-radius: 24px;
  -webkit-animation: gif 1s infinite linear;
  animation: gif 1s infinite linear;
  clip: rect(0 auto 12px 0);
}

.wx_loading2 .wx_loading_icon {
  border-color: #99cc33;
  top: 7px;
}

@keyframes gif {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(1turn);
  }
}
.mmm-container {
  width: 100%;
  height: 100%;
}
.in-list {
  width: 100%;
  /*margin-top: 10px;*/
  background-color: white;
  overflow: hidden;
}

.in-list li {
  height: 44px;
}

.in-list .in-item {
  height: 44px;
  line-height: 44px;
  padding: 0 10px;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  display: -moz-box;
  vertical-align: middle;
}
.myMM-Order {
  font-size: 14px;
  color: #232326;
}
.fl {
  float: left;
  font-size: 0;
}
.fr {
  float: right;
  font-size: 0;
}
.iconfont {
  color: #606060;
  font-family: "iconfont" !important;
  font-size: 18px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
  padding-left: 9px;
}
.myMM-AllOrder {
  font-size: 12px;
  color: #848689;
  margin-right: 3px;
  vertical-align: top;
  color: #fff
}
.in-item-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.new-order-track{
  display: flex;
  div{
    font-size: 14px;
    color: #6e6e6e;
  }
}
.new-order-track {
    padding: 10px 20px;
    /* border: 1px solid #999;*/
}

.new-order-track span {
    display: block;
    font-size: 14px;
    color: #6e6e6e;
}

.new-order-track .new-txt-rd2 {
    color: #25ae5f
}

.new-order-flow {
    margin-top: 20px;
    padding: 5px 10px 30px;
    border-left: 1px solid #ccc;
}

.new-of-storey li {
    position: relative;
    padding: 15px 10px;
    border-bottom: 1px dotted #e8e5e5;
}

.new-of-storey li .icon {
    position: absolute;
    top: 42%;
    left: -15px;
    width: 9px;
    height: 9px;
    border-radius: 5px;
    background: #ccc;
}

.new-of-storey li span {
    display: block;
    font-size: 14px;
    color: #6e6e6e;
}

.new-of-storey li span {
    display: block;
    font-size: 14px;
    color: #6e6e6e;
}

.new-of-storey li.on .icon {
    background: #25ae5f;
}

.new-of-storey li.on span {
    color: #25ae5f;
}
.view{
  display: flex;
  align-items: center;
}
</style>
